export default {
    name: 'HarimauFresh',
    fullName: 'HarimauFresh',
    companyName: "HarimauFresh",
    url: 'https://uat.harimaufresh.com',
    color: '#91C73D',
    meta_title: 'HarimauFresh',
    meta_description: 'Groceries - We provide families with the essentials for your household by sourcing for the freshest and best quality items. Pick from more than 10,000 different types of groceries, fresh produce, dairy, confectionary, household to baby products. We deliver to you fresh, fast and on demand.',
    meta_keyword: '',
    author: {
        name: "Ant Internet Shd Bhd",
        url: "https://ant-internet.com/",
        registration_number: "1199944-V",
    },
    contacts: {
        address: '715 Fake Street, New York 10021 USA',
        email: 'stroyka@example.com',
        phone: '(800) 060-0730',
    },
};
