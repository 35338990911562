import store from "../store";
import { storeCountries, storeGeneralInfo, storeSystemInfo } from "../store/generalInfo";
import { appendFormData, checkVisitorID, paramBuilder } from "../services/utils";
import theme from "../data/theme";
const axios = require("axios");

export async function getSystemSettings() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/system-setting";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: {
                    Accept: "application/json",
                    withCredentials: true,
                },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
                store.dispatch(storeGeneralInfo(data.data?.store));
                store.dispatch(storeSystemInfo(data.data?.system));
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function getCountryList() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/country?per_page=unlimited";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: {
                    Accept: "application/json",
                    withCredentials: true,
                },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);

                if (data?.data && data.data.data) store.dispatch(storeCountries(data.data.data));
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function getStates(code) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/state/" + code + "?per_page=unlimited";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: {
                    Accept: "application/json",
                    withCredentials: true,
                },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getAnnouncement(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/announcement" + queryString;
    if (params.per_page) url += "&per_page=" + params.per_page;
    if (params.page) url += "&page=" + params.page;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: {
                    Accept: "application/json",
                    withCredentials: true,
                },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function sendContactForm(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/contact-us";
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(url, formData, {
                headers: {
                    Accept: "application/json",
                    withCredentials: true,
                },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export function getSEOSettings(fieldName) {
    let SEOData = store.getState().generalInfo?.info?.seo_info;

    if (SEOData && SEOData[fieldName]) return SEOData[fieldName];
    else return theme[fieldName];
}

export async function getShippingFeeInfo() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/shipping-fee";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: {
                    Accept: "application/json",
                    withCredentials: true,
                },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getShippingTimeSlotList() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/custom-shipping-time-slot";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: {
                    Accept: "application/json",
                    withCredentials: true,
                },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);

                if (data?.data && data.data.data) store.dispatch(storeCountries(data.data.data));
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function getBranchList(params) {
    const queryString = paramBuilder(params)

    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/custom-branch" + queryString;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: {
                    Accept: "application/json",
                    withCredentials: true,
                },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    }); 
}