// react
import React from "react";
import { Link } from "react-router-dom";

export default function FooterAboutUs() {
    return (
        <div className="site-footer__widget footer-about">
            {/* <h5 className="footer-about__title">About</h5> */}

            <Link to="/">
                <img src="images/logos/logo.png" alt="logo" className="footer-logo" />
            </Link>
            <p className="footer-about__text">
                About
                <br />
                HarimauFresh
            </p>
            <div className="footer-about__text">
                We provide families with the essentials for your household by sourcing for the freshest and best quality items. Pick from
                more than 10,000 different types of groceries, fresh produce, dairy, confectionary, household to baby products. We deliver
                to you fresh, fast and on demand.
            </div>
            <div className="mt-3 pt-1 mb-3">
                <h5 className="footer-about__title">Partner</h5>
                <img src={`/images/icons/sri_ternak_logo.png?`} alt="" style={{ width: "213px", height: "auto" }} />
            </div>
        </div>
    );
}
