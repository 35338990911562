import Echo from "laravel-echo";
import { updateNotification } from "../store/user";
import { getAuthHeader } from "./utils";

export function connectToChannel(uuid, dispatch) {
    window.Pusher = require("pusher-js");
    window.Echo = new Echo({
        broadcaster: "pusher",
        key: process.env.REACT_APP_WEBSOCKET_KEY,
        wsHost: process.env.REACT_APP_WS_HOST,
        wsPort: process.env.REACT_APP_WS_PORT,
        // wssHost: process.env.REACT_APP_WS_HOST,
        // wssPort: process.env.REACT_APP_WS_PORT,
        forceTLS: false,
        disableStats: true,
        authEndpoint: process.env.REACT_APP_API_URL + "broadcasting/auth",
        auth: {
            headers: getAuthHeader(),
        },
    });

    if (window.Echo) {
        window.Echo.channel(`general.member`).notification((notification) => {
            dispatch(updateNotification(1, "update"));
        });

        window.Echo.private(`user.${uuid}`).notification((notification) => {
            dispatch(updateNotification(1, "update"));
        });
    }
}
