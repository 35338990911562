// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// application
import NavPanel from "./NavPanel";
import Search from "./Search";
import Topbar from "./Topbar";
import { LogoSvg } from "../../svg";
import CartIndicator from "./IndicatorCart";
import { connect } from "react-redux";
import IndicatorAccount from "./IndicatorAccount";
import { mobileMenuOpen } from "../../store/mobile-menu";
import { getAccessToken } from "../../api/auth";
import IndicatorNotifcation from "./IndicatorNotifcation";

function Header(props) {
    const { layout, openMobileMenu } = props;
    const token = getAccessToken();

    let bannerSection;

    if (layout === "default") {
        bannerSection = (
            <div className="site-header__middle">
                <button type="button" className="mobile-header__menu-button desktop" onClick={openMobileMenu}>
                    <div className="bar-style">
                        <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i>
                    </div>
                </button>
                <div className="site-header__logo">
                    <Link to="/">
                        <img src={"images/logos/logo.png?v="+new Date()} alt="logo" className="header-logo" />
                    </Link>
                </div>
                <div className="header-menu">
                    
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__user">
                    {token ? <IndicatorNotifcation from="header" /> : null}

                    <CartIndicator from="header" />

                    <IndicatorAccount from="header" />
                </div>

                {/* <div className="menu-right-top">
                    {token ? null : <Link to="/login">Login / Resgiter</Link>}
                </div> */}
            </div>
        );
    }

    return (
        <div className="site-header">
            {/* <Topbar /> */}
            <div className="container">{bannerSection}</div>
            {/* <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div> */}
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
