// react
import React, { useState, useEffect } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as GENERAL from "../../api/general"
import { showGeneralError } from '../../services/utils';
import BlockLoader from "../blocks/BlockLoader";

export default function CustomTopbar(props) {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedBranch, setSelecedBranch] = useState(localStorage.getItem("branchid") ? localStorage.getItem("branchid") : "");
    const [branches, setBranches] = useState([]);
    const [apiLoading, setApiLoading] = useState(true);

    useEffect(() => {
        setApiLoading(true);

        let params = {};
        params.per_page = 999;
        params.sort_by = "created_at";
        params.sort_type = "asc";
        GENERAL.getBranchList(params).then((res) => {
            setBranches(res.data.data);

            if (res.data.data.length == 0) {
                handleBranchChange("", true);
            } else {
                if (!selectedBranch) {
                    handleBranchChange((res.data.data.length ? res.data.data[0].uuid : ""), true);
                } else {
                    let match = false;
                    for (let i = 0; i < res.data.data.length; i++) {
                        const element = res.data.data[i];
                        if (selectedBranch == element.uuid) {
                            match = true;
                            break;
                        }
                    }
                    if (!match) {
                        handleBranchChange((res.data.data.length ? res.data.data[0].uuid : ""), true);
                    }
                }
            }
            setApiLoading(false);
        })
            .catch((err) => {
                showGeneralError(err);
            });
    }, []);

    function handleBranchChange($branch, $reload = false) {
        if ($branch != selectedBranch) {
            localStorage.setItem("branchid", $branch);
            if ($reload) {
                window.location.reload();
            }
        }
    }

    if (branches.length != 0) {
        return (
            <>
                <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)} centered>
                    <ModalHeader toggle={() => setModalVisible(false)}>Please select a branch</ModalHeader>
                    <ModalBody>
                        {
                            branches.map((item) => {
                                return (
                                    <ul key={item.uuid} className="menu menu--layout--classic c-pointer" style={{ width: "100%", padding: 0 }}
                                        onClick={() => { handleBranchChange(item.uuid, true); setModalVisible(false); }}>
                                        <li><a style={{ padding: "15px 15px" }}>
                                            {item.name} {item.uuid == selectedBranch ? "(Selecting)" : ""}
                                            <br />
                                            <div style={{ paddingTop: '3px' }}>
                                                <small><i className="fas fa-map-marker-alt"></i>&nbsp;{item.address},&nbsp;{item.postcode},&nbsp;{item.city},&nbsp;{item.state.name},&nbsp;{item.country.name}</small>
                                            </div>
                                        </a></li>
                                    </ul>
                                )
                            })
                        }
                    </ModalBody>
                </Modal>
                <div className="site-header__topbar topbar" style={{ backgroundColor: "#91C73D" }}>
                    <div className="topbar__container container">
                        <div className="topbar__row">
                            <div className="topbar__spring" />
                            <div className="topbar__item">
                                {apiLoading ? (
                                    <div className="block-empty-page custom-topbar-loader">
                                        <BlockLoader />
                                    </div>) :
                                    <label className="c-pointer" onClick={() => { setModalVisible(true) }} style={{ color: "#fff", margin: "0" }}>
                                        <i className="fas fa-map-marker-alt" style={{ paddingRight: "5px", fontWeight: "700" }}></i>
                                        <span style={{ fontWeight: "700" }}>
                                            Branch:&nbsp;
                                            {
                                                branches.map((item) => {
                                                    if (item.uuid == selectedBranch) {
                                                        return item.name
                                                    }
                                                })
                                            }
                                        </span>
                                    </label>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
}
