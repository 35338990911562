// react
import React from "react";

// data stubs
import theme from "../../data/theme";

export default function FooterContacts(props) {
    const { storeInfo } = props;
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Store Information</h5>

            <ul className="footer-contacts__contacts">
                {storeInfo?.company_info?.name ? (
                    <li>
                        <i className="footer-contacts__icon fa fa-building" />
                        <span style={{ fontWeight: 600 }}>
                            {storeInfo?.company_info?.name} &nbsp;
                            {storeInfo?.company_info?.reg_num ? <small>({storeInfo?.company_info?.reg_num})</small> : null}
                        </span>
                    </li>
                ) : null}

                {storeInfo?.company_info?.address1 ? (
                    <li>
                        <i className="footer-contacts__icon fas fa-map-marker-alt" />
                        {storeInfo?.company_info?.address1 +
                            (storeInfo?.company_info?.postcode ? ", " + storeInfo?.company_info?.postcode : "") +
                            (storeInfo?.company_info?.city ? ", " + storeInfo?.company_info?.city : "") +
                            (storeInfo?.company_info?.state ? ", " + storeInfo?.company_info?.state : "") +
                            (storeInfo?.company_info?.country ? ", " + storeInfo?.company_info?.country : "")}
                    </li>
                ) : null}

                {storeInfo?.contact_info?.number1 || storeInfo?.contact_info?.number2 ? (
                    <li>
                        <i className="footer-contacts__icon fas fa-mobile-alt" />
                        <a href={"tel:" + storeInfo?.contact_info?.number1}>{storeInfo?.contact_info?.number1}</a>
                    </li>
                ) : null}

                {storeInfo?.contact_info?.number2 ? (
                    <li>
                        <i className="footer-contacts__icon fas fa-phone" />
                        <a href={"tel:" + storeInfo?.contact_info?.number2}>{storeInfo?.contact_info?.number2}</a>
                    </li>
                ) : null}

                {storeInfo?.contact_info?.email ? (
                    <li>
                        <i className="footer-contacts__icon far fa-envelope" />
                        <a href={"mailto:" + storeInfo?.contact_info?.email}>{storeInfo?.contact_info?.email}</a>
                    </li>
                ) : null}
            </ul>
            <br />
            <h5 className="footer-contacts__title">Payment Options</h5>
            <img src={`/images/icons/logo_harimaufresh_payment_method_3rows.jpg?`} alt="" className="img-fluid" />
        </div>
    );
}
