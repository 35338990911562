// react
import React, { useState, useEffect } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { checkProductInCart, useWindowSize, showGeneralError } from "../../services/utils";
import { cartAddItem, cartRemoveItem } from "../../store/cart";
import { quickviewOpen, quickviewClose } from "../../store/quickview";
import { Link, useHistory } from "react-router-dom";

function InputCartQty(props) {
    const { size, className, product, min, step, layout, from } = props;
    const cart = useSelector((state) => state?.cart);
    const dispatch = useDispatch();
    let qty = checkProductInCart(cart.items, product, "qty");
    let prodInCart = checkProductInCart(cart.items, product);
    const [quantity, setQuantity] = useState(0);
    const [max, setMax] = useState(99);
    const [cancelFn, setCancelFn] = useState(() => () => {});
    const history = useHistory();
    const windowSize = useWindowSize();
    const classes = classNames("input-number", className);
    const formControlClasses = classNames("form-control input-number__input", {
        "form-control-sm": size === "sm",
        "form-control-md": size === "md",
        "form-control-lg": size === "lg",
    });

    useEffect(() => {
        let numStock = 0;
        product.variant_choices.forEach((item) => {
            numStock += item.stock_count;
        });
        if (max !== numStock) {
            setMax(numStock);
        }
    }, [product]);

    useEffect(() => {
        setQuantity(qty);
    }, [qty]);

    useEffect(() => () => cancelFn(), [cancelFn]);

    const handleChange = (event) => {
        if (event.target.value.trim() === "") {
            handleQtyChange("");
        } else {
            if (event.target.value <= max) {
                const value = parseFloat(event.target.value);
                handleQtyChange(Number.isNaN(value) ? min || 0 : value);
            } else if (event.target.value > max) {
                const value = parseFloat(event.target.value);
                handleQtyChange(max);
            }
        }
    };

    function hasMultipleVariant() {
        return product.variant_choices[0] && product.variant_choices.length > 1 ? true : false;
    }

    const handleQtyChange = async (newQty) => {
        let timer;
        const newCancelFn = () => {
            clearTimeout(timer);
        };

        if (hasMultipleVariant()) {
            timer = setTimeout(() => {
                dispatch(quickviewOpen(product));
            }, 300);
        } else {
            setQuantity(newQty);
            timer = setTimeout(
                () => {
                    let shouldUpdateQty = 0;
                    if (qty !== newQty) {
                        if (qty < newQty) {
                            shouldUpdateQty = newQty - qty;
                            cartUpdateQty(shouldUpdateQty, "add");
                        } else {
                            shouldUpdateQty = qty - newQty;
                            cartUpdateQty(shouldUpdateQty, "minus");
                        }
                    }
                },
                newQty ? 800 : 0
            );
        }
        setCancelFn(() => newCancelFn);
    };

    const cartUpdateQty = async (quantity, type) => {
        if (type == "add") {
            try {
                await dispatch(cartAddItem(product, product.variant_choices[0].uuid, [], quantity, null));
            } catch (res) {
                if (res.errors && res.errors["variant_group.out_stock"]) {
                    setQuantity(qty);
                }
            }
        } else await dispatch(cartRemoveItem(prodInCart, quantity));
    };

    const handleAddMouseDown = () => {
        change(1);
        changeByTimer(1);
    };

    const handleSubMouseDown = () => {
        change(-1);
        changeByTimer(-1);
    };

    /**
     * @param direction - one of [-1, 1]
     */
    function change(direction) {
        let newValue = (quantity === "" || Number.isNaN(quantity) ? 0 : quantity) + step * direction;

        if (max !== null) {
            newValue = Math.min(max, newValue);
        }
        if (min !== null) {
            newValue = Math.max(min, newValue);
        }

        if (newValue !== quantity) {
            handleQtyChange(newValue);
        }
    }

    /**
     * @param direction - one of [-1, 1]
     */
    const changeByTimer = (direction) => {
        let interval;
        const timer = setTimeout(() => {
            interval = setInterval(() => change(direction), 50);
        }, 300);

        const documentMouseUp = () => {
            clearTimeout(timer);
            clearInterval(interval);

            document.removeEventListener("mouseup", documentMouseUp, false);
        };

        document.addEventListener("mouseup", documentMouseUp, false);
    };

    return quantity == 0 ? (
        from == "prod-detail" ? (
            <div className="product__actions-item product__actions-item--addtocart">
                <button
                    type="button"
                    onClick={() => handleQtyChange(1)}
                    className={"btn btn-primary " + (windowSize.width < 576 ? "btn-md" : "btn-lg")}
                >
                    Add to Cart
                </button>
            </div>
        ) : null
    ) : (
        <div className={classes}>
            <input className={formControlClasses} type="number" onChange={(e) => handleChange(e)} value={quantity} />

            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className={"input-number__add" + (quantity + 1 > max ? " disabled" : "")} onMouseDown={() => handleAddMouseDown()} />
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className={"input-number__sub" + (quantity <= min ? " disabled" : "")} onMouseDown={() => handleSubMouseDown()} />
        </div>
    );
}

InputCartQty.propTypes = {
    size: PropTypes.oneOf(["sm", "md", "lg"]),
    step: PropTypes.number,
    min: PropTypes.number,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
};

InputCartQty.defaultProps = {
    step: 1,
    min: 0,
};

export default InputCartQty;
