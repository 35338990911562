// react
import React, { useEffect, useState } from "react";

// application
import Currency from "./Currency";

function OrderTable(props) {
    const { order, from } = props;

    return order ? (
        <table className="order-details">
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody className="card-table__body card-table__body--merge-rows">
                {order.order_details.map((item) => {
                    return (
                        <tr key={item.id}>
                            <td className={item.additional?.is_pwp ? "pl-5" : ""}>
                                {item.product_detail?.product?.name + " x " + item.quantity}
                                <ul className="cart-table__options mt-0">
                                    <li>
                                        {item.product_detail?.variant_group?.combinations.length == 1 &&
                                            item.product_detail?.variant_group?.combinations[0]?.name == "main"
                                            ? null // if no variant options
                                            : item.product_detail?.variant_group?.combinations.map((opt, index) => {
                                                return (
                                                    opt.name +
                                                    (item.product_detail?.variant_group?.combinations.length !== index + 1 ? ", " : "")
                                                );
                                            })}
                                    </li>
                                    {item.addon_info?.length > 0
                                        ? item.addon_info?.map((addon, index) => {
                                            return <li>{"+ " + addon.addon_item.additional_json.options[addon.index]?.name}</li>;
                                        })
                                        : null}
                                </ul>
                            </td>
                            <td>
                                {parseFloat(item.discount_amount) > 0 ? (
                                    <>
                                        <span className="product__old-price" style={{ fontSize: 13 }}>
                                            <Currency value={item.ori_total_price} />
                                        </span>
                                        <Currency value={item.total_price} />
                                    </>
                                ) : (
                                    <Currency value={item.total_price} />
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
            <tbody className="card-table__body card-table__body--merge-rows total-summary">
                <tr>
                    <th>Subtotal</th>
                    <td>
                        <Currency
                            value={
                                order.ori_subtotal_amount -
                                (order?.discount_amount -
                                    Math.abs(order?.additional?.combo_discount_amount ? order?.additional?.combo_discount_amount : 0))
                            }
                        />
                    </td>
                </tr>
                {order.additional?.combo_discount_amount ? (
                    <tr>
                        <th>Combo Deals Savings</th>
                        <td>
                            {order.additional?.combo_discount_amount.toString().charAt(0) == "-" ? "- " : ""}
                            {order.additional?.combo_discount_amount.toString().charAt(0) == "-" ? (
                                <Currency value={parseFloat(order.additional?.combo_discount_amount.toString().substring(1)).toFixed(2)} />
                            ) : (
                                <Currency value={order.additional?.combo_discount_amount} />
                            )}
                        </td>
                    </tr>
                ) : null}
                {order.total_tax_amount && parseFloat(order.total_tax_amount) > 0 ? (
                    <tr>
                        <th>Tax Amount</th>
                        <td>
                            <Currency value={order.total_tax_amount} />
                        </td>
                    </tr>
                ) : null}

                {order?.total_shipping_fee && order?.shipping_info?.type == 2 ? (
                    <tr>
                        <th>Shipping</th>
                        <td>
                            <Currency value={order.total_shipping_fee} />
                        </td>
                    </tr>
                ) : null}

                {parseFloat(order?.total_free_shipping_fee) > 0 && order?.shipping_info?.type == 2 ? (
                    <tr>
                        <th>Free Shipping</th>
                        <td>
                            - <Currency value={order.total_free_shipping_fee} />
                        </td>
                    </tr>
                ) : null}

                {order.event_data_info?.vouchers && order.event_data_info?.vouchers[0] ? (
                    <tr>
                        <th>
                            Voucher Discount<br />
                            {order.event_data_info?.vouchers[0]?.code ? (
                                <span className="platform-applied-voucher">{order.event_data_info?.vouchers[0]?.code}</span>
                            ) : null}
                        </th>
                        <td>
                            {"- "}
                            <Currency value={order.voucher_amount} />
                        </td>
                    </tr>
                ) : null}
                {order?.credits_redeem_json && order?.credits_redeem_json.final_credits_redeem ? (
                    <tr>
                        <th>
                            Redeem Credits
                        </th>
                        <td>
                            - <Currency value={order.credits_redeem_json.final_credits_redeem} />
                        </td>
                    </tr>
                ) : null}
                {order.adjust_amount ? (
                    <tr>
                        <th>Adjust Amount</th>
                        <td>
                            {order.adjust_amount.toString().charAt(0) == "-" ? "- " : ""}
                            {order.adjust_amount.toString().charAt(0) == "-" ? (
                                <Currency value={parseFloat(order.adjust_amount.toString().substring(1)).toFixed(2)} />
                            ) : (
                                <Currency value={order.adjust_amount} />
                            )}
                        </td>
                    </tr>
                ) : null}

            </tbody>
            <tfoot className="total-summary">
                <tr>
                    <th>Total</th>
                    <td className="total-price">
                        <Currency value={order.total_amount} />
                    </td>
                </tr>
            </tfoot>
        </table>
    ) : null;
}

export default OrderTable;
