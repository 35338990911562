// react
import React, { useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// application
import { mobileUserMenuClose } from "../../store/mobile-menu";
import { ArrowPointToLeft } from "../../svg";
import * as AUTH from "../../api/auth";
import { toast } from "react-toastify";
import { getDefaultAvatar } from "../../services/utils";
import accountMenuList from "../../data/accountMenuList";
const defaultAvatar = getDefaultAvatar();

function MobileUserMenu(props) {
    const { user, mobileMenuState, closeMobileUserMenu } = props;
    const history = useHistory();
    const [imgError, setImgError] = useState(false);

    const classes = classNames("mobile-usermenu", {
        "mobile-usermenu--open": mobileMenuState.userMenuOpen,
    });

    function handleLogout() {
        closeMobileUserMenu();
        toast.info("Logout Successful", { autoClose: 2000 });
        AUTH.logout(history);
    }

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobile-usermenu__backdrop" onClick={closeMobileUserMenu} />
            <div className="mobile-usermenu__body">
                <div className="mobile-usermenu__header">
                    <div className="col-12 pt-3">
                        <div className="back-arrow" onClick={closeMobileUserMenu}>
                            <ArrowPointToLeft />
                        </div>
                    </div>
                    <div className="mobile-usermenu__user-avatar">
                        {!user?.image || imgError ? (
                            <img src={defaultAvatar} alt="user-avatar" />
                        ) : (
                            <img src={user ? user.image : defaultAvatar} alt="user-avatar" onError={(e) => setImgError(true)} />
                        )}
                        <div className="name">{user ? user.fullName : ""}</div>
                        <div className="points">{user ? user.email : ""}</div>
                        {/* <div className="points">{user ? user.membersonPoint + " points" : ""}</div> */}
                    </div>
                </div>
                <div>
                    <ul className="user-menu-list">
                        {accountMenuList.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link to={item.url} onClick={closeMobileUserMenu}>
                                        {item.name}
                                    </Link>
                                </li>
                            );
                        })}
                        <hr />
                        <li>
                            <Link to="" onClick={handleLogout}>
                                Log Out
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
    user: state.user.profile,
});

const mapDispatchToProps = {
    closeMobileUserMenu: mobileUserMenuClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileUserMenu);
