// react
import React, { useEffect, useState, Suspense, lazy } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import BlockLoader from "./blocks/BlockLoader";
import ScrollToTop from "./ScrollToTop";

// data stubs
import { checkVisitorID, redirectTo404 } from "../services/utils";
import { getAccessToken } from "../api/auth";
import { getMyCart } from "../api/cart";
import store from "../store";
import { updateNotification, updateUser } from "../store/user";
import { getProductCategories } from "../api/product";
import { getAnnouncement, getSEOSettings } from "../api/general";

// application
import Quickview from "./shared/Quickview";
import Header from "./header";
import Footer from "./footer";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import MobileUserMenu from "./mobile/MobileUserMenu";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../services/ErrorFallback.js";
import ShippingInfomation from "./shared/ShippingInfomation";
import CustomTopbar from "./header/CustomTopbar";
import { storeCategories } from "../store/generalInfo";
import cloneDeep from "lodash/cloneDeep";

// pages
const HomePage = lazy(() => import("./home/HomePage"));
const AccountLayout = lazy(() => import("./account/AccountLayout"));
const AccountPageLogin = lazy(() => import("./account/AccountPageLogin"));
const AccountPageRegister = lazy(() => import("./account/AccountPageRegister"));

const OrderDetail = lazy(() => import("./shop/OrderDetail"));
const PageCart = lazy(() => import("./shop/ShopPageCart"));
const PageCheckout = lazy(() => import("./shop/ShopPageCheckout"));
const ShopPageProduct = lazy(() => import("./shop/ShopPageProduct"));
const ShopPageBrands = lazy(() => import("./shop/ShopPageBrands"));
const ShopPageCategory = lazy(() => import("./shop/ShopPageCategory"));
const ShopPageOrderSuccess = lazy(() => import("./shop/ShopPageOrderSuccess"));
const PageApplePay = lazy(() => import("./shop/ShopPageApplePay"));
const ShopPageEvent = lazy(() => import("./shop/ShopPageEvent"));
const ShopPageAddOnDeals = lazy(() => import("./shop/ShopPageAddOnDeals"));
const ShopPageComboDeals = lazy(() => import("./shop/ShopPageComboDeals"));
const ShopPageEventProduct = lazy(() => import("./shop/ShopPageEventProduct"));
const ShopPageBlogList = lazy(() => import("./shop/ShopPageBlogList"));

const SitePageAboutUs = lazy(() => import("./site/SitePageAboutUs"));
const SitePageContactUs = lazy(() => import("./site/SitePageContactUs"));
const SitePageNotFound = lazy(() => import("./site/SitePageNotFound"));
const SitePageForgotPassword = lazy(() => import("./site/SitePageForgotPassword"));
const SitePageResendVerification = lazy(() => import("./site/SitePageResendVerification"));
const SitePageResetPassword = lazy(() => import("./site/SitePageResetPassword"));
const SitePageTerms = lazy(() => import("./site/SitePageTerms"));
const SitePageShippingInformation = lazy(() => import("./site/SitePageShippingInformation"));
// const SitePageSolutions = lazy(() => import("./site/SitePageSolutions"));
const SitePageBlog = lazy(() => import("./site/SitePageBlog"));
const SitePageDynamic = lazy(() => import("./site/SitePageDynamic"));

function filterNotVisibleCategory(item) {
    return item.child.filter((childItem) => childItem.is_visible == 1);
}

function Layout(props) {
    const { match, settings } = props;
    const [socialLinks, setSocialLinks] = useState(null);
    const [storeInfo, setStoreInfo] = useState(null);
    const [finishLoadSetting, setFinishLoadSetting] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [categories, setCategories] = useState([]);
    const [announcements, setAnnouncements] = useState([]);

    const token = getAccessToken();

    useEffect(() => {
        if (checkVisitorID("cart")) {
            getMyCart(store);
        }
        if (!token) {
            store.dispatch(updateUser(null));
            store.dispatch(updateNotification(0));
        }
    }, [token]);

    useEffect(() => {
        getProductCategories({
            per_page: 100,
            appends: "child_cat,image",
            level: 1,
        }).then((res) => {
            if (res.data && res.data.data) {
                let temp = cloneDeep(res.data.data)
                store.dispatch(storeCategories(temp));
                setCategories(recursiveCategory(res.data.data));
            }
        });
    }, []);

    function recursiveCategory(categories) {
        categories.forEach((item) => {
            if (item.child) {
                item.child = filterNotVisibleCategory(item);
                recursiveCategory(item.child);
            }
        });
        return categories;
    }

    useEffect(() => {
        if (settings) {
            if (settings.system?.one_signal?.is_active == 1 && settings.system?.one_signal?.app_id) {
                window.startOneSignal(settings.system?.one_signal?.app_id);
                checkVisitorID();
            }
            setSocialLinks(settings.system?.social);
            setStoreInfo((settings.store));
            setFinishLoadSetting(true);
        }
    }, [settings]);

    useEffect(() => {
        if (window.OneSignal && settings?.system?.one_signal?.is_active == 1 && settings?.system?.one_signal?.app_id) {
            window.OneSignal.push(function () {
                // Occurs when the user's subscription changes to a new value.
                window.OneSignal.on("subscriptionChange", function (isSubscribed) {
                    // console.log("The user's subscription state is now:", isSubscribed);
                    checkVisitorID();
                });

                // This event can be listened to via the `on()` or `once()` listener.
            });
        }
    }, [window?.OneSignal]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        if (firstLoad) setFirstLoad(false);
        else getMyCart(store);

        getAnnouncement({ per_page: 10, pgs: "og" }).then((res) => {
            if (res.data && res.data.data) {
                setAnnouncements(res.data.data);
            }
        });
    }, []);

    const handleScroll = () => {
        if (document.getElementById("mobile-sticky")) {
            if (window.pageYOffset > 100) {
                document.getElementById("mobile-sticky").classList.add("fixed");
            } else {
                document.getElementById("mobile-sticky").classList.remove("fixed");
            }
        }
    };

    // page use gray bg instead of white
    const checkPageReturnClass = (pathname) => {
        if (
            pathname.indexOf("/voucher-details/") > -1 ||
            pathname == "/cart" ||
            pathname == "/checkout" ||
            pathname == "/login" ||
            pathname == "/register" ||
            pathname == "/resend-verification" ||
            pathname == "/forgot-password" ||
            pathname.indexOf("/order-tracking/") > -1 ||
            pathname.indexOf("/addon-deal/") > -1
        )
            return "site__body grey";
        else return "site__body";
    };

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href={window.location.pathname} />
                <meta name="og:url" property="og:url" content={window.location.pathname} />
                <meta name="og:type" property="og:type" content={"website"} />
                <meta name="twitter:url" property="twitter:url" content={window.location.href} />
            </Helmet>

            <ToastContainer autoClose={5000} theme="colored" newestOnTop hideProgressBar />
            <Quickview />

            <ShippingInfomation logisticInfo={storeInfo?.logistic_info} />

            <MobileMenu categories={categories} />

            <MobileUserMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <CustomTopbar />
                    <MobileHeader />
                </header>

                <header id="sticky" className="site__header sticky-header d-lg-block d-none">
                    <CustomTopbar />
                    <Header />
                </header>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense
                        fallback={
                            <div className="block-empty-page">
                                <BlockLoader />
                            </div>
                        }
                    >
                        <div id="site-body" className={checkPageReturnClass(window.location.pathname)}>
                            <ScrollToTop>
                                <Switch>
                                    {/*
                                // Home
                                */}
                                    <Route
                                        exact
                                        path={"/"}
                                        render={(props) => (
                                            <HomePage
                                                {...props}
                                                homeSectionCMS={storeInfo?.home_section_cms}
                                                announcements={announcements}
                                                categories={categories}
                                                finishLoad={finishLoadSetting}
                                            />
                                        )}
                                    />

                                    {token
                                        ? null
                                        : [
                                              <Route key="register" exact path="/register" component={AccountPageRegister} />,
                                              <Route key="login" exact path="/login" render={(props) => <AccountPageLogin {...props} />} />,
                                          ]}
                                    {/*
                            // Shop
                            */}
                                    <Route
                                        exact
                                        path="/brand/:brandSlug"
                                        render={(props) => (
                                            <ShopPageCategory
                                                {...props}
                                                columns={3}
                                                viewMode="grid"
                                                sidebarPosition="start"
                                                brandSlug={props.match.params.brandSlug}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/category/:categorySlug"
                                        render={(props) => (
                                            <ShopPageCategory
                                                {...props}
                                                columns={3}
                                                viewMode="grid"
                                                sidebarPosition="start"
                                                baseCategories={categories}
                                                categorySlug={props.match.params.categorySlug}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/search-products"
                                        render={(props) => (
                                            <ShopPageCategory
                                                {...props}
                                                columns={6}
                                                viewMode="grid"
                                                sidebarPosition="start"
                                                baseCategories={categories}
                                                searchValue={decodeURI(
                                                    props.location?.search ? props.location.search.substring(1)?.split("=")[1] : ""
                                                )}
                                            />
                                        )}
                                    />

                                    <Route
                                        exact
                                        path="/product/:productSlug"
                                        render={(props) => (
                                            <ShopPageProduct {...props} layout="standard" productSlug={props.match.params.productSlug} />
                                        )}
                                    />

                                    <Route exact path="/cart" component={PageCart} />
                                    <Route exact path="/checkout" component={PageCheckout} />
                                    <Route exact path="/order-success" component={ShopPageOrderSuccess} />
                                    <Route exact path="/apple-pay" component={PageApplePay} />
                                    <Route exact path="/all-brands" component={ShopPageBrands} />
                                    <Route
                                        exact
                                        path="/addon-deal/:productSlug"
                                        render={(props) => <ShopPageAddOnDeals {...props} productSlug={props.match.params.productSlug} />}
                                    />
                                    <Route
                                        exact
                                        path="/combo-deal/:productSlug"
                                        render={(props) => <ShopPageComboDeals {...props} productSlug={props.match.params.productSlug} />}
                                    />
                                    {/*
                            // Account
                            */}
                                    <Route path="/account" component={AccountLayout} />
                                    <Route
                                        exact
                                        path="/order-tracking/:orderCode"
                                        render={(props) => <OrderDetail {...props} fromPublic={true} />}
                                    />

                                    {/*
                            // Site
                            */}
                                    <Route exact path="/forgot-password" component={SitePageForgotPassword} />
                                    <Route exact path="/password-reset" component={SitePageResetPassword} />
                                    <Route exact path="/resend-verification" component={SitePageResendVerification} />
                                    <Route exact path="/shipping-information" component={SitePageShippingInformation} />
                                    {/* <Route exact path="/about-us" component={SitePageAboutUs} /> */}
                                    <Route exact path="/term-and-condition" component={SitePageTerms} />
                                    <Route exact path="/contact-us" component={SitePageContactUs} />
                                    {/* <Route exact path="/our-service" component={SitePageSolutions} /> */}

                                    <Route
                                        exact
                                        path="/event-products/:slug"
                                        render={(props) => <ShopPageEventProduct {...props} slug={props.match.params.slug} />}
                                    />
                                    <Route
                                        exact
                                        path="/event/:slug"
                                        render={(props) => <ShopPageEvent {...props} slug={props.match.params.slug} />}
                                    />
                                    <Route
                                        exact
                                        path="/blog/:slug"
                                        render={(props) => <SitePageBlog {...props} blogSlug={props.match.params.slug} />}
                                    />
                                    <Route
                                        exact
                                        path="/page/:slug"
                                        render={(props) => <SitePageDynamic {...props} pageSlug={props.match.params.slug} />}
                                    />
                                    <Route exact path="/blog" component={ShopPageBlogList} />

                                    {/*
                                    // Page Not Found
                                    */}
                                    <Route exact path="/goto-404" component={SitePageNotFound} />
                                    <Route exact path="/404-not-found" component={SitePageNotFound} />

                                    <Route component={redirectTo404} />
                                </Switch>
                            </ScrollToTop>
                        </div>
                    </Suspense>
                </ErrorBoundary>
                <footer className="site__footer">
                    <Footer socialLinks={socialLinks} storeInfo={storeInfo} />
                </footer>
            </div>
        </React.Fragment>
    );
}

export default Layout;
